import React, { useRef, useLayoutEffect } from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { device } from "../styles/mediaQueries";
import gsap from "gsap";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";

const AboutWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  width: 100%;
  gap: 2rem;
  padding: 12rem 2rem 2rem;
  flex-direction: column-reverse;

  @media ${device.mediaMinLarge} {
    padding: 2rem;
    flex-direction: row;
    min-height: 100vh;
    gap: 4rem;
    padding: 6rem 1rem 1rem;
  }
`;

const Column = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-family: "Work Sans", sans-serif;
    text-transform: uppercase;
    font-size: 2.4rem;
    color: rgb(50, 55, 62);
  }

  .text-content-wrapper {
    padding: 0;
    @media ${device.mediaMinLarge} {
      padding: 0 0 0 2rem;
    }
  }

  .contactImgWrapper {
    height: 100%;
    max-height: 90vh;
    /* @media ${device.mediaMinMedium} {
      max-height: 100%;
    } */
  }

  &.contact-column-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.clipped {
    --clip: 0 100% 0 0;
    clip-path: inset(var(--clip));
  }
  .heroTitle {
    margin: 0;
  }
  /* .heroTitle,
  .heroText {
    visibility: hidden;
  } */
`;

export const query = graphql`
  query AboutPageQuery {
    biography: sanityBio {
      id
      title
      _rawBioText
    }
  }
`;

const About = props => {
  const { data, errors } = props;
  const ref = useRef(null);
  const tl = useRef(null);

  const bio = (data || {}).biography;

  console.log("bio.bioText: ", bio._rawBioText);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline().to(".clipped", {
        duration: 2,
        ease: "power4.out",
        "--clip": "0% 0% 0% 0%",
        delay: 0.5
      });
      // .fromTo(
      //   [".heroTitle", ".heroText"],
      //   {
      //     yPercent: 20,
      //     stagger: 0.75
      //   },
      //   {
      //     autoAlpha: 1,
      //     yPercent: 0
      //   },
      //   "<+=0.25"
      // );
    }, ref);
    return () => ctx.revert();
  }, []);

  return (
    <Layout>
      {/* <SEO title="about" description={site.description} keywords={site.keywords} /> */}
      <SEO title="about" />
      {/* <OuterWrapper> */}
      <AboutWrapper ref={ref}>
        <Column className="contact-column-right">
          <div className="text-content-wrapper">
            <h1 className="heroTitle">{bio.title}</h1>
            {bio._rawBioText && <BlockContent blocks={bio._rawBioText || []} />}
          </div>
        </Column>
        <Column className="clipped">
          <StaticImage
            src="../assets/about.jpeg"
            alt="About"
            placeholder="blurred"
            layout="constrained"
            fit="contain"
            // objectPosition="0 0"
            className="contactImgWrapper"
            imgClassName="contactImg"
          />
        </Column>
      </AboutWrapper>
      {/* </OuterWrapper> */}
    </Layout>
  );
};

export default About;
